/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import {
  useTable,
  useFilters,
  useGlobalFilter,
  useAsyncDebounce,
  useSortBy,
} from "react-table";
import data from "./Data.json";
import "../App.css";

// Define a default UI for filtering
function GlobalFilter({ globalFilter, setGlobalFilter }) {
  //const count = preGlobalFilteredRows.length                                //returns length of array
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <span>
      <input
        className="search-field"
        value={value || ""}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder={`Search...`}
      />
      <div>
        <button
          className="girth"
          onClick={(e) => {
            var str = "Girth";
            setValue(str);
            setGlobalFilter(str);
          }}
        >
          Girth
        </button>

        <button
          className="point"
          onClick={(e) => {
            var str = "Point";
            setValue(str);
            setGlobalFilter(str);
          }}
        >
          Point
        </button>

        <button
          className="on_surface_length"
          onClick={(e) => {
            var str = "on surface length";
            setValue(str);
            setGlobalFilter(str);
          }}
        >
          On surface length
        </button>

        <button
          className="Contoured"
          onClick={(e) => {
            var str = "Contoured";
            setValue(str);
            setGlobalFilter(str);
          }}
        >
          Contoured
        </button>

        <button
          className="Tape"
          onClick={(e) => {
            var str = "Tape";
            setValue(str);
            setGlobalFilter(str);
          }}
        >
          Tape
        </button>

        <button
          className="Arms"
          onClick={(e) => {
            var str = "Arms";
            setValue(str);
            setGlobalFilter(str);
          }}
        >
          Arms
        </button>

        <button
          className="Foot"
          onClick={(e) => {
            var str = "Foot";
            setValue(str);
            setGlobalFilter(str);
          }}
        >
          Foot
        </button>

        <button
          className="Head"
          onClick={(e) => {
            var str = "Head";
            setValue(str);
            setGlobalFilter(str);
          }}
        >
          Head
        </button>

        <button
          className="LowerBody"
          onClick={(e) => {
            var str = "Lower Body";
            setValue(str);
            setGlobalFilter(str);
          }}
        >
          Lower Body
        </button>

        <button
          className="Neck"
          onClick={(e) => {
            var str = "Neck";
            setValue(str);
            setGlobalFilter(str);
          }}
        >
          Neck
        </button>

        <button
          className="Torso"
          onClick={(e) => {
            var str = "Torso";
            setValue(str);
            setGlobalFilter(str);
          }}
        >
          Torso
        </button>

        <button
          className="Torso_Arms"
          onClick={(e) => {
            var str = "Torso + Arms";
            setValue(str);
            setGlobalFilter(str);
          }}
        >
          Torso+Arms
        </button>
      </div>
    </span>
  );
}

function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  //const count = preFilteredRows.length

  return (
    <input
      className="form-control"
      value={filterValue || ""}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
      // placeholder={`Search records...`}
    />
  );
}

function Table({ columns, data }) {
  var [hasError, setHasError] = useState(false);
  try {
    if (data && data.length === 0) {
      throw new Error("JSON data file is empty");
    }
  } catch {
    setHasError(true);
  }
  const defaultColumn = React.useMemo(
    //`useMemo` will only recompute the memorized
    // value when one of the `deps` has changed.
    () => ({
      // Default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    // preGlobalFilteredRows,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
    },
    useFilters,
    useGlobalFilter,
    useSortBy
  );

  return (
    <div className="fixed-width">
      <GlobalFilter
        // preGlobalFilteredRows={preGlobalFilteredRows}
        globalFilter={state.globalFilter}
        setGlobalFilter={setGlobalFilter}
      />

      <table className="table" {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th
                key={column.id} // Add the key prop directly here
                {...column.getHeaderProps(column.getSortByToggleProps())}
              >
                {column.render("Header")}
                <span className="symbol">
                  {column.isSorted
                    ? column.isSortedDesc
                      ? " ▼"
                      : " ▲"
                    : " ▽"}
                </span>
              </th>
            ))}
          </tr>
        ))}
      </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            if (row.original.definition !== "" && row.original.publish !== "n") {
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  ))}
                </tr>
              );
            }
            return null;
          })}
        </tbody>
      </table>
      <br />

      {hasError && <ErrorComponent></ErrorComponent>}
    </div>
  );
}

function ErrorComponent() {
  return <h1>JSON data empty error</h1>;
}

function FilterTableComponent() {
  const columns = React.useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
        disableFilters: true,
      },
      {
        Header: "Label",
        accessor: "label",
      },
      {
        Header: "Definition",
        accessor: "definition",
        Cell1: ({ cell: { value } }) => {
          //var ret;        

          if (1) {
            var string = value;

            var res = string.split("%");

            console.log(res[0]); //string
            // console.log(res[1]);
            // console.log(res[2]);

            var part = string.substring(
              string.indexOf("%") + 1,
              string.lastIndexOf("%")
            );
            console.log(part);

            var string1 = part.split(",");
            console.log(string1[0]); //link
            console.log(string1[1]); //address

            // var ret=res[0]+string1[1];
            // alert(ret);
          }
          // var el = document.createElement( 'html' );
          // el.innerHTML = "<html><body><a href='test0'>test01</a><a href='test1'>test02</a><a href='test2'>test03</a></body></html>";

          // el.getElementsByTagName( 'a' );
          //alert(string1);

          // var abc = parse('<a>Pranjal</a>satyarthi<a>prototech</a>');
          //console.log(abc.childNodes[2]);
          // alert(abc);

          //var xyz=<a>string1[0]</a>;

          return value;
        },
        // var str=value;
        // var params= [];
        // params.push('<a>', '</a>');
        // var rx = /[%]/g;
        // value=str.replace(rx,'<a>'

        //console.log(str)
        // console.log(value)
        //     return value;

        disableFilters: true,
      },

      {
        Header: "Type",
        accessor: "type",
      },
      //,

      // {
      //     Header: 'L1 Validation',
      //     accessor: 'L1 Validation',
      // }

      {
        Header: "Tape / Contoured",
        accessor: "tapeContoured",
        disableFilters: true,
      },

      // {
      //     Header: 'Aligned to Skeleton',
      //     accessor: 'Aligned to Skeleton',
      //     disableFilters: true
      //  },
      //     Header: 'Perpendicular Axis',
      //     accessor: 'Perpendicular Axis',
      // },{
      //     Header: 'ML Identification',
      //     accessor: 'ML Identification',
      // },{
      //     Header: 'Posenet ML/3DMU Front ML/3D MU Back ML',
      //     accessor: 'Posenet ML/\n3DMU Front ML/\n3D MU Back ML',
      // },{
      //     Header: 'ML Ignored?',
      //     accessor: 'ML Ignored?',
      // },{
      //     Header: 'Geometry Based',
      //     accessor: 'Geometry Based',
      // },{
      //     Header: 'Geometry Correction',
      //     accessor: 'Geometry Correction',
      // },{
      //     Header: 'Primary Landmark',
      //     accessor: 'Primary Landmark',
      // },{
      //     Header: 'Dependent Landmark',
      //     accessor: 'Dependent Landmark',
      // },
      // {
      //     Header: 'Source of Definition',
      //     accessor: 'Source of Definition',
      //     disableFilters: true
      // }

      {
        Header: "Region",
        accessor: "region",
      },
      {
        Header: "Image",
        accessor: "imageurl",
        maxWidth: 70,
        minWidth: 70,
        Cell: ({ cell: { value } }) => <img src={value} width={180} alt="" />,
        disableFilters: true,
        disableSortBy: true,
      },

    //   {
    //     Header: "Related Metrics",
    //     accessor: "Related Matrics",
    //     Cell: ({ cell: { value } }) => {
    //       //console.log(value[0])
    //       // console.log(value)

    //       return (
    //         <div>
    //           <a
    //             onClick={function showtip() {
    //               data.map((cell) => {
    //                 if (cell.ID === value) {
    //                   alert(cell.Definition);
    //                 }
    //               });
    //             }}
    //           >
    //             {value}
    //           </a>{" "}
    //         </div>
    //       );
    //       //      ,
    //       // //   <a onClick={  */}
    //       //         function showtip(){

    //       //             data.map(cell => {

    //       //                 if(cell.ID===value[1])
    //       //                        {
    //       //                            alert(cell.Definition)
    //       //                        }

    //       //         })

    //       //         }} >{value[1]}</a></div>

    //       //  )
    //     },
    //   },
    ],
    []
  );

  return <Table columns={columns} data={data} />;
}

export default FilterTableComponent;
